// Dependencies
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Link from "gatsby-link";
import GraphImg from "graphcms-image";

// Components
import AccordionHeader from "./accordionHeader";
import WeaponPower from "./weaponPower";
import Target from "./target";

interface WeaponSectionProps {
  title: string;
  content: object[];
}

export const renderTile = (weapon, index) => {
  return (
    <StyledLink to={`/arsenal/${weapon.slug}`} key={index}>
      <PictureWrapper>
        <StyledTarget>
          <Target />
        </StyledTarget>
        <ImageWrapper>
          <StyledGraphImg
            image={{
              handle: weapon.image.handle,
              width: 350,
              height: 350 / (weapon.image.width / weapon.image.height),
            }}
            blurryPlaceholder={false}
            withWebp
          />
        </ImageWrapper>
      </PictureWrapper>
      <StyledParagraph>{weapon.name}</StyledParagraph>
      <WeaponPower power={weapon.gunPower} />
    </StyledLink>
  );
};

const WeaponSection: React.FC<WeaponSectionProps> = ({ title, content }) => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (typeof window !== "undefined" && window.innerWidth > 420) {
      setOpen(true);
    }
  }, []);

  return (
    <>
      <AccordionHeader
        title={title.toUpperCase()}
        open={open}
        setOpen={setOpen}
      />
      {open && (
        <WeaponsContainer>
          {content.map((weapon, index) => renderTile(weapon, index))}
        </WeaponsContainer>
      )}
    </>
  );
};

export default WeaponSection;

const WeaponsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: ${(props) => props.theme.space[4]}px;
  grid-row-gap: ${(props) => props.theme.space[5]}px;
  margin: ${(props) => props.theme.space[4]}px 0
    ${(props) => props.theme.space[7]}px 0;

  @media screen and (min-width: ${(props) => props.theme.breakpoints[0]}) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${(props) => props.theme.space[5]}px;
    grid-row-gap: ${(props) => props.theme.space[6]}px;
    margin: ${(props) => props.theme.space[5]}px 0
      ${(props) => props.theme.space[8]}px 0;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints[1]}) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: ${(props) => props.theme.space[6]}px;
    grid-row-gap: ${(props) => props.theme.space[7]}px;
    margin: ${(props) => props.theme.space[6]}px 0
      ${(props) => props.theme.space[9]}px 0;
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints[2]}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: ${(props) => props.theme.space[7]}px;
    grid-row-gap: ${(props) => props.theme.space[8]}px;
    margin: ${(props) => props.theme.space[7]}px 0
      ${(props) => props.theme.space[10]}px 0;
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: center;
  position: relative;
  color: ${(props) => props.theme.colors.white};

  &:hover {
    color: ${(props) => props.theme.colors.primary};
  }
`;

const PictureWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: ${(props) => props.theme.space[3]}px;
`;

const ImageWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
`;

const StyledGraphImg = styled(GraphImg)`
  width: 100%;
`;

const StyledParagraph = styled.p`
  color: inherit;
  font-size: ${(props) => props.theme.fontSizes[2]}px;
  font-weight: 700;
  text-align: center;
  margin-bottom: ${(props) => props.theme.space[2]}px;
  max-width: 139px;
  @media screen and (min-width: ${(props) => props.theme.breakpoints[1]}) {
    font-size: ${(props) => props.theme.fontSizes[4]}px;
    max-width: 200px;
  }
`;

const StyledTarget = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: relative;
  opacity: 0.5;
`;
