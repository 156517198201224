// Dependencies
import React, { SetStateAction } from "react";
import styled from "styled-components";

import AccordionChevron from "./svg/accordionChevron";

interface AccordionHeaderProps {
  title: string;
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
}

const AccordionHeader: React.FC<AccordionHeaderProps> = ({
  title,
  open,
  setOpen,
}) => {
  return (
    <HeaderContainer onClick={() => setOpen(!open)}>
      <TitleContainer>{title.toUpperCase()}</TitleContainer>
      <AccordionChevron up={open} />
    </HeaderContainer>
  );
};

export default AccordionHeader;

const HeaderContainer = styled.div`
  display: flex;
  cursor: pointer;

  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    margin-left: 0;
  }
`;

const TitleContainer = styled.div`
  user-select: none;
  position: relative;
  display: inline-block;
  font-size: ${(props) => props.theme.fontSizes[3]}px;
  font-weight: bold;
  padding-top: ${(props) => props.theme.space[3]}px;
  padding-bottom: ${(props) => props.theme.space[3]}px;
  border-bottom: 4px solid ${(props) => props.theme.colors.primary};

  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    font-size: ${(props) => props.theme.fontSizes[5]}px;
  }
`;
