// Dependencies
import React, { FC, useContext } from "react";
import styled from "styled-components";
import GraphImg from "graphcms-image";
import { Link } from "gatsby";

// Components
import { FilterSelectionValueContext } from "../components/filterSelectionValueContext";
import Target from "../components/target";

interface CategoryProps {
  name: string;
  image: { handle: string; width: number; height: number };
  direction: string;
  keyName: string;
  keyValue: string;
  big?: Boolean;
}

const Category: FC<CategoryProps> = ({
  keyValue,
  keyName,
  name,
  image,
  direction,
  big,
}) => {
  const [, setKeyValue] = useContext(FilterSelectionValueContext);
  return (
    <CategoryContainer
      onClick={() => setKeyValue(keyValue)}
      state={{ keyName: keyName, keyValue: keyValue }}
      to={`/arsenal${direction}`}
    >
      <ImageContainer>
        {keyName === "countries" ? (
          // <StyledImage
          //   fluid={image.childImageSharp.fluid}
          //   objectFit="contain"
          // />
          <StyledImage
            image={{
              handle: image.handle,
              width: 320,
              height: 320 / (image.width / image.height),
            }}
            outerWrapperClassName="GraphCmsImageWrapper"
            blurryPlaceholder={false}
            withWebp
            // style={{ width: 400 }}
          />
        ) : (
          <>
            <StyledTarget>
              <Target />
            </StyledTarget>
            {/* <StyledImageAmmo
              fluid={image.childImageSharp.fluid}
              big={big}
              objectFit="contain"
            /> */}
            <StyledImageAmmo
              image={{
                handle: image.handle,
                width: 320,
                height: 320 / (image.width / image.height),
              }}
              big={big}
              outerWrapperClassName="GraphCmsImageWrapperAbsolute"
              blurryPlaceholder={false}
              withWebp
              // style={{ position: "absolute" }}
            />
          </>
        )}
      </ImageContainer>
      <Title>{name.toUpperCase()}</Title>
    </CategoryContainer>
  );
};

export default Category;

const CategoryContainer = styled(Link)`
  max-width: 400px;
  display: flex;
  flex-direction: column;
  margin: 0 ${(props) => props.theme.space[3]}px
    ${(props) => props.theme.space[6]}px ${(props) => props.theme.space[3]}px;

  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    margin-bottom: ${(props) => props.theme.space[8]}px;
    width: 100%;
    max-width: 250px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    max-width: 300px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    max-width: 400px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  max-height: 400px;
  height: 200px;
  width: 200px;
  position: relative;
  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    height: 250px;
    width: 100%;
  }
  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    height: 300px;
    width: 100%;
  }
  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    height: 400px;
    width: 100%;
  }
  .GraphCmsImageWrapper {
    flex: 1;
    height: 100%;
    width: 100%;
  }

  .GraphCmsImageWrapperAbsolute {
    flex: 1;
    height: 100%;
    width: 100%;
    position: absolute !important;
  }
`;

const StyledImage = styled(GraphImg)`
  height: 100%;
  width: 100%;
  object-fit: contain;
  max-height: 400px;
  max-width: 400px;
  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    width: 100%;
  }
  img {
    object-fit: contain !important;
    object-position: center center !important;
  }
`;

const Title = styled.p`
  text-align: center;
  letter-spacing: 0.1rem;
  color: ${(props) => props.theme.colors.white};
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSizes[3]}px;
  margin-top: ${(props) => props.theme.space[3]}px;
  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    font-size: ${(props) => props.theme.fontSizes[5]}px;
    margin-top: ${(props) => props.theme.space[7]}px;
    display: flex;
    justify-content: center;
  }
`;

const StyledTarget = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  position: relative;
  opacity: 0.5;
`;

const StyledImageAmmo = styled(GraphImg)<{ big: Boolean }>`
  height: 80% !important;
  width: ${(props) => (props.big ? "100%" : "80%")} !important;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  overflow: visible !important;
  transform: translate(-50%, -50%);

  img {
    object-fit: contain !important;
    object-position: center center !important;
  }
`;
