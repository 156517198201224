// Dependencies
import React from "react";
import styled from "styled-components";

// Components
import WeaponSection from "./weaponSection";

interface CategoryListProps {
  weapons: object[];
  keyName?: string;
  keyValue?: string;
}

const categories = [
  {
    name: "pistolety",
    category: "Pistols",
  },
  {
    name: "pistolety maszynowe",
    category: "SubmachineGuns",
  },
  {
    name: "karabinki automatyczne",
    category: "MachineGuns",
  },
  {
    name: "karabiny i karabinki",
    category: "Rifles",
  },
  {
    name: "strzelby gładkolufowe",
    category: "Shotguns",
  },
  {
    name: "rewolwery",
    category: "Revolvers",
  },
];

const CategoryList: React.FC<CategoryListProps> = ({
  weapons,
  keyName,
  keyValue,
}) => {
  const filter = (keyName, keyValue, arr) => {
    let newArr = [];
    arr.forEach((element) => {
      //eslint-disable-next-line
      element[keyName]
        ? element[keyName] instanceof Array
          ? element[keyName].some((el) => el.name && el.name === keyValue)
            ? newArr.push(element)
            : null
          : keyName === "category"
          ? element[keyName] === keyValue
            ? newArr.push(element)
            : null
          : element[keyName].name && element[keyName].name === keyValue
          ? newArr.push(element)
          : null
        : null;
    });
    return newArr;
  };

  const filteredWeapons =
    keyName && keyValue ? filter(keyName, keyValue, weapons) : weapons;

  let filteredCategory = [];

  return (
    <ListContainer>
      {categories.map((category, index) => {
        filteredCategory = filter(
          "category",
          category.category,
          filteredWeapons
        );
        return (
          filteredCategory.length > 0 && (
            <WeaponSection title={category.name} content={filteredCategory} />
          )
        );
      })}
    </ListContainer>
  );
};

export default CategoryList;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
