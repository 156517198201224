// Dependencies
import React, { FC } from "react";
import styled from "styled-components";

interface WeaponPowerProps {
  power: number;
}

interface BarProps {
  full: boolean;
}

const Bar: FC<BarProps> = ({ full }) => {
  return <StyledBar full={full} />;
};

const StyledBar = styled.div<{ full: boolean }>`
  height: 5px;
  width: 15px;
  background-color: ${props =>
    props.full ? props.theme.colors.primary : props.theme.colors.greyBar};
  @media screen and (min-width: ${props => props.theme.breakpoints[1]}) {
    height: 6px;
  width: 20px;
  }
`;

const WeaponPower: FC<WeaponPowerProps> = ({ power }) => {
  let validatedPower = Math.max(power, 0);
  validatedPower = Math.min(power, 5);
  let orangeBars = [];
  let greyBars = [];
  for (let i = 0; i < validatedPower; i++) {
    orangeBars.push(<Bar key={i} full />);
  }
  for (let i = 5; i > validatedPower; i--) {
    greyBars.push(<Bar key={i} full={false} />);
  }
  return (
    <IndicatorContainer>
      {orangeBars}
      {greyBars}
    </IndicatorContainer>
  );
};

export default WeaponPower;

const IndicatorContainer = styled.div`
  display: flex;
  width: 109px;
  justify-content: space-between;
  margin-top: ${props => props.theme.space[2]}px;
  @media screen and (min-width: ${props => props.theme.breakpoints[1]}) {
    width: 148px;
  }
`;
