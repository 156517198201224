import React, { FC } from "react";
import styled from "styled-components";

interface Props {
  up?: boolean;
}

const AccordionChevron: FC<Props> = ({ up }) => {
  return (
    <StyledSVG up={up} x="0px" y="0px" viewBox="0 0 185.343 185.343">
      <g>
        <g>
          <path
            d="M51.707,185.343c-2.741,0-5.493-1.044-7.593-3.149c-4.194-4.194-4.194-10.981,0-15.175
			l74.352-74.347L44.114,18.32c-4.194-4.194-4.194-10.987,0-15.175c4.194-4.194,10.987-4.194,15.18,0l81.934,81.934
			c4.194,4.194,4.194,10.987,0,15.175l-81.934,81.939C57.201,184.293,54.454,185.343,51.707,185.343z"
          />
        </g>
      </g>
    </StyledSVG>
  );
};

export default AccordionChevron;

const StyledSVG = styled.svg<{ up: boolean }>`
  min-width: 20px;
  width: 20px;
  min-height: 20px;
  height: 20px;
  fill: ${(props) => props.theme.colors.white};
  transform: rotate(${(props) => (props.up ? "270deg" : "90deg")});
  transition: 0.4s ease;
  margin: auto 10px;
  padding: 2px;
  @media screen and (min-width: ${(props) => props.theme.breakpoints[1]}) {
    width: 24px;
    height: 24px;
  }
`;
