// Dependencies
import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";

const Target = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/circle.png" }) {
        childImageSharp {
          fluid(quality: 85, maxWidth: 400) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <StyledImg fluid={data.file.childImageSharp.fluid} objectFit="contain" />
  );
};

export default Target;

const StyledImg = styled(Img)`
  width: 100%;
  height: 100%;
`;
