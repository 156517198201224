// Dependencies
import React, { FC } from "react";
import styled from "styled-components";
import Img from "gatsby-image";

interface Props {
  title: string;
  image: {
    childImageSharp: {
      fluid: any;
    };
  };
}

const TitleImage: React.FC<Props> = ({ title, image }) => {
  return (
    <TitleImageContainer>
      <StyledImg fluid={image.childImageSharp.fluid} />
      <StyledContainer>
        <Title>{title.toUpperCase()}</Title>
      </StyledContainer>
    </TitleImageContainer>
  );
};

export default TitleImage;

const TitleImageContainer = styled.div`
  display: none;
  position: relative;
  @media screen and (min-width: ${(props) => props.theme.breakpoints[1]}) {
    margin-top: -${(props) => props.theme.space[4]}px;
    margin-bottom: ${(props) => props.theme.space[6]}px;
    display: flex;
    height: 250px;
    background: ${(props) => props.theme.colors.grey};
    justify-content: center;
    align-items: center;
  }
`;

const StyledContainer = styled.div`
  display: inline-block;
  max-width: ${(props) => props.theme.containerWidth}px;
  padding: 0 ${(props) => props.theme.space[2]}px;
  z-index: 10;
  @media screen and (min-width: ${(props) => props.theme.breakpoints[1]}) {
    padding: 0 ${(props) => props.theme.space[4]}px;
  }
`;

export const Title = styled.h2`
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.fontSizes[6]}px;
  font-weight: 500;
  letter-spacing: 0.3rem;
  @media screen and (min-width: ${(props) => props.theme.breakpoints[0]}) {
    font-size: ${(props) => props.theme.fontSizes[8]}px;
  }
`;

const StyledImg = styled(Img)`
  position: absolute !important;
  height: 100%;
  width: 100%;
`;
