// Dependencies
import React, { Fragment, useContext } from "react";
import styled from "styled-components";
import { graphql } from "gatsby";

// Components
import { Container } from "./index";
import Category from "../components/category";
import TitleImage from "../components/title-image";
import { FilterSelectionContext } from "../components/filterSelectionContext";
import SEO from "../components/seo";
import CategoryList from "../components/categoryList";
import Chevron from "../components/svg/chevron";

interface Props {
  data: {
    allDataJson: {
      edges: {
        node: {
          arsenal: {
            titleImage: any;
          };
        };
      };
    };
    cms: {
      weapons: object[];
      countries: {
        name: string;
        image: { handle: string; width: number; height: number };
      }[];
      ammoTypes: {
        name: string;
        image: { handle: string; width: number; height: number };
        big: boolean;
      }[];
    };
  };
}

const filters = [
  {
    name: "Rodzaj amunicji",
    slug: "ammoType",
  },
  {
    name: "Kraj",
    slug: "countries",
  },
];

const Arsenal: React.FC<Props> = ({ data }) => {
  const [active, setActive] = useContext(FilterSelectionContext);
  const weapons = data.cms.weapons;
  const countries = data.cms.countries;
  const ammoTypes = data.cms.ammoTypes;

  return (
    <>
      <TitleImage
        image={data.allDataJson.edges[0].node.arsenalPage.titleImage}
        title={"Arsenał"}
      />
      <StyledContainer>
        {active ? (
          <BackButtonContainer onClick={() => active && setActive("")}>
            <ArrowContainer>
              <Chevron small />
            </ArrowContainer>
            <StyledFilter active={false}>Powrót</StyledFilter>
          </BackButtonContainer>
        ) : null}
        <FilterContainer>
          <FilterHeader>Filtruj:</FilterHeader>
          <FilterWrapper>
            {filters.map((filter, index) => {
              return (
                <Fragment key={index}>
                  <StyledFilter
                    active={active === filter.slug}
                    onClick={() =>
                      active !== filter.slug && setActive(filter.slug)
                    }
                  >
                    {filter.name}
                  </StyledFilter>
                  {index !== filters.length - 1 && <Bar>|</Bar>}
                </Fragment>
              );
            })}
          </FilterWrapper>
        </FilterContainer>
        {!active ? <CategoryList weapons={weapons} /> : null}
        {active === "ammoType" && (
          <CategoryContainer>
            {ammoTypes.map((ammoType, index) => {
              return (
                <Category
                  key={index}
                  name={ammoType.name}
                  image={ammoType.image}
                  big={ammoType.big ? ammoType.big : false}
                  direction="/weapons_list"
                  keyName="ammoType"
                  keyValue={ammoType.name}
                />
              );
            })}
          </CategoryContainer>
        )}
        {active === "countries" && (
          <CategoryContainer>
            {countries.map((country, index) => {
              return (
                <Category
                  key={index}
                  name={country.name}
                  image={country.image}
                  direction="/weapons_list"
                  keyName="countries"
                  keyValue={country.name}
                />
              );
            })}
          </CategoryContainer>
        )}
      </StyledContainer>
      <SEO />
    </>
  );
};

export default Arsenal;

const StyledContainer = styled(Container)`
  position: relative;
  margin-bottom: ${(props) => props.theme.space[3]}px;
  margin-top: -${(props) => props.theme.space[4]}px;
`;

const BackButtonContainer = styled.div`
  cursor: pointer;
  margin-bottom: ${(props) => props.theme.space[2]}px;
  display: inline-block;
  margin-left: -${(props) => props.theme.space[3]}px;

  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    margin: 0 -${(props) => props.theme.space[3]}px;
    margin-left: ${(props) => props.theme.space[2]}px;
    margin-bottom: ${(props) => props.theme.space[8]}px;
    position: absolute;
    top: 16px;
    left: 5px;
    z-index: 10;
  }
`;

const ArrowContainer = styled.div`
  display: inline-block;
  margin-left: ${(props) => props.theme.space[3]}px;
  margin-right: -${(props) => props.theme.space[2]}px;
`;

const FilterContainer = styled.div`
  display: inline-block;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: ${(props) => props.theme.space[2]}px;
  margin-left: -${(props) => props.theme.space[3]}px;

  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    display: flex;
    flex-direction: row;
    margin-bottom: ${(props) => props.theme.space[8]}px;
    position: absolute;
    top: 16px;
    right: 5px;
    z-index: 10;
  }
`;

const FilterHeader = styled.span`
  font-size: ${(props) => props.theme.fontSizes[3]}px;
  color: ${(props) => props.theme.colors.white};
  font-weight: 300;
  letter-spacing: 0.1rem;
  display: inline-block;
  margin-left: ${(props) => props.theme.space[3]}px;
  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    font-size: ${(props) => props.theme.fontSizes[4]}px;
  }
`;

const StyledFilter = styled.span<{ active: Boolean }>`
  user-select: none;
  font-size: ${(props) => props.theme.fontSizes[3]}px;
  color: ${(props) =>
    props.active ? props.theme.colors.primary : props.theme.colors.white};
  font-weight: 500;
  letter-spacing: 0.1rem;
  cursor: pointer;
  transition: 0.3s ease;
  margin: 0 ${(props) => props.theme.space[3]}px;
  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    font-size: ${(props) => props.theme.fontSizes[4]}px;
  }
`;

const Bar = styled.span`
  /* display: none; */

  @media (min-width: ${(props) => props.theme.breakpoints[2]}) {
    display: block;
    color: ${(props) => props.theme.colors.white};
    font-size: ${(props) => props.theme.fontSizes[4]}px;
    font-weight: bold;
  }
`;

const CategoryContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    padding-top: ${(props) => props.theme.space[8]}px;
    align-items: center;
  }
  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    flex-direction: column;
  }

  @media (min-width: ${(props) => props.theme.breakpoints[3]}) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const FilterWrapper = styled.div`
  display: inline-block;
  justify-content: center;
  margin: ${(props) => props.theme.space[2]}px 0;

  @media (min-width: ${(props) => props.theme.breakpoints[1]}) {
    display: flex;
    margin: 0;
  }
`;

export const query = graphql`
  query {
    allDataJson {
      edges {
        node {
          arsenalPage {
            titleImage {
              childImageSharp {
                fluid(quality: 85, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    cms {
      weapons(stage: PUBLISHED, orderBy: weaponId_ASC) {
        weaponId
        countries {
          name
        }
        category
        slug
        name
        image {
          handle
          width
          height
        }
        ammoType {
          name
        }
        gunPower
        cells1
        cells2
        characteristics
        description
        featuredGuns
        linksToWeapons
      }
      countries(orderBy: order_ASC) {
        name
        order
        image {
          handle
          width
          height
        }
      }
      ammoTypes(orderBy: order_ASC) {
        name
        big
        order
        image {
          handle
          width
          height
        }
      }
    }
  }
`;
